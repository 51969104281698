import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import imgEn1 from "./assets/img/img-en1.png";
import imgEn2 from "./assets/img/img-en2.png";
import imgEn3 from "./assets/img/img-en3.png";
import imgEn4 from "./assets/img/img-en4.png";
import imgEn5 from "./assets/img/img-en5.png";
import imgEn7 from "./assets/img/img-en7.png";
import imgEn8 from "./assets/img/img-en8.png";
import imgGe1 from "./assets/img/img-ge1.png";
import imgGe2 from "./assets/img/img-ge2.png";
import imgGe3 from "./assets/img/img-ge3.png";
import imgGe4 from "./assets/img/img-ge4.png";
import imgGe5 from "./assets/img/img-ge5.png";
import imgGe7 from "./assets/img/img-ge7.png";
import imgGe8 from "./assets/img/img-ge8.png";
import { I18Namepsace, Language } from "./i18n";
import "./sass/index.scss";
import styles from "./sass/modules/app.module.scss";

export default function App() {
  const [t, i18n] = useTranslation(I18Namepsace.General);

  const [gateActive, setGateActive] = useState<boolean>(true);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [langSwitcher, setLangSwitcher] = useState<boolean>(false);

  const setCookie = (name: string, value: string | boolean, days: number) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  const getCookie = (name: string) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  useEffect(() => {
    const cookieVal = getCookie("ageGate");

    if (!cookieVal) {
      setShowContent(false);
    }
    else {
      setShowContent(true);
    }
  }, [showContent]);

  useEffect(() => {
    const cookieLang = getCookie("language");

    if (cookieLang) {
      i18n.changeLanguage(cookieLang);
    }
  }, []);

  useEffect(() => {
    setCookie("language", i18n.language, 30);
  }, [i18n.language]);

  return (
    <div className={styles.home}>
      <div className={styles.langSwitcher}>
        <div className={`${styles.language} ${styles.activeLang}`} onClick={() => setLangSwitcher(!langSwitcher)}>
          <span className={styles[`language-${i18n.language}`]} />
        </div>

        <div className={`${styles.langList} ${langSwitcher ? styles.active : undefined}`}>
          {[Language.English, Language.Georgian].map((language, key) => (
            <label key={key} className={`${styles.language} ${i18n.language === language ? styles.active : undefined}`} onClick={() => setLangSwitcher(false)}>
              <input
                type="radio"
                name="language"
                value={language}
                checked={i18n.language === language}
                onChange={() => {
                  i18n.changeLanguage(language);
                }}
              />
              <span className={styles[`language-${language}`]}>
                {language}
              </span>
            </label>
          ))}
        </div>

        <div className={`${styles.backdrop} ${langSwitcher ? styles.active : undefined}`} onClick={() => setLangSwitcher(false)} />
      </div>

      {showContent ?
        (
          <>
            {i18n.language === Language.English ?
              (
                <>
                  <div className={styles.main}>
                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/geo_test2">
                      <img srcSet={`${imgEn1} 2x`} src={imgEn1} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/heets_en">
                      <img srcSet={`${imgEn2} 2x`} src={imgEn2} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/3d_signpost_en">
                      <img srcSet={`${imgEn3} 2x`} src={imgEn3} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/3d">
                      <img srcSet={`${imgEn4} 2x`} src={imgEn4} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/santasdeer">
                      <img srcSet={`${imgEn5} 2x`} src={imgEn5} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/3d_line_en-772017002">
                      <img srcSet={`${imgEn7} 2x`} src={imgEn7} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/atelier-invite-278959065-268028661">
                      <img srcSet={`${imgEn8} 2x`} src={imgEn8} alt="" />
                    </a>
                  </div>

                  <div className={styles.txt}>
                    {t("General.ftr")}
                  </div>
                </>
              )
              :
              (
                <>
                  <div className={styles.main}>
                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/geo_test2-231152861">
                      <img srcSet={`${imgGe1} 2x`} src={imgGe1} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/heets_en-091003012">
                      <img srcSet={`${imgGe2} 2x`} src={imgGe2} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/3d_signpost_en-616112188">
                      <img srcSet={`${imgGe3} 2x`} src={imgGe3} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/3d-691474444">
                      <img srcSet={`${imgGe4} 2x`} src={imgGe4} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/santasdeer-103828991">
                      <img srcSet={`${imgGe5} 2x`} src={imgGe5} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/3d_line_en-772017002-192696285">
                      <img srcSet={`${imgGe7} 2x`} src={imgGe7} alt="" />
                    </a>

                    <a href="https://app.aryel.io/3599ccad-93e3-4a7e-8131-db45654fd4e2/atelier-invite-278959065-268028661-314841080">
                      <img srcSet={`${imgGe8} 2x`} src={imgGe8} alt="" />
                    </a>
                  </div>

                  <div className={styles.txt}>
                    {t("General.ftr")}
                  </div>
                </>
              )
            }
          </>
        )
        :
        (
          <>
            {gateActive ?
              (
                <div className={styles.popup}>
                  <h1>
                    {t("General.h1")}
                  </h1>

                  <div className={styles.btnWr}>
                    <div
                      className={styles.btn}
                      onClick={() => {
                        setShowContent(true);
                        setCookie("ageGate", true, 30);
                      }}
                    >
                      {t("General.btn1")}
                    </div>

                    <div
                      className={styles.btn}
                      onClick={() => {
                        setGateActive(false);
                      }}>
                      {t("General.btn2")}
                    </div>
                  </div>

                  <div className={styles.footerText}>
                    {t("General.txt1")}
                  </div>
                </div>
              )
              :
              (
                <div className={styles.popup}>
                  <h1>
                    {t("General.h2")}
                  </h1>

                  <div className={styles.btnWr}>
                    <div
                      className={styles.btn}
                      onClick={() => {
                        setGateActive(true);
                      }}
                    >
                      {t("General.btn3")}
                    </div>
                  </div>

                  <div className={styles.footerText}>
                    {t("General.txt1")}
                  </div>
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}
