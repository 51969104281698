import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/**
 * Namespaces of i18next.
 */
export enum I18Namepsace {
  General = "general"
}

export enum Language {
  Georgian = "ge",
  English = "en"
}

/**
 * Get language according "lang" GET parameter of current page.
 */
export function defaultLanguage(): string {
  return new URLSearchParams(window.location.search).get("lang") ?? "ge";
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}-general.json`
    },
    lng: defaultLanguage(),
    fallbackLng: defaultLanguage(),
    debug: (process.env.NODE_ENV || "development") === "development",
    interpolation: {
      // Not needed for react as it escapes by default.
      escapeValue: false,
    },
    react: {
      // wait: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
    },
    defaultNS: I18Namepsace.General,
    ns: I18Namepsace.General,
    load: "currentOnly",
  });

export default i18n;